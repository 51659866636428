.main {
    display: flex;
    min-height: 75vh;
    flex-flow: column nowrap;
    justify-content: space-between;
}

.rowCenter {
  display: flex;
  align-items: center;
  flex-flow: row;
}
.noMargin {
  margin: 0
}

.box_style {
  border-radius: 15px;
  border-color: #E5E4E4;
  border-style: solid;
  border-width: thin;
  padding: 10px 0px;
  margin-bottom: 9px;
}