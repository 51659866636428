.tooltipText {
  font-family: Arial, Helvetica, sans-serif;
}

.criterioNivel {
  cursor: pointer
}

.course {
  margin-left: 15px;
}

.accordion {
  margin-bottom: 26px;
}

.accordion__header {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  border: 1px solid #444;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.accordion__header > h4 {
  pointer-events: none;
}

.accordion__body {
  padding: 0 18px;
  background-color: white;
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease-out;
  interpolate-size: allow-keywords;
}

.accordion__body > a > p {
  width: fit-content;
}

.accordion__header--active:not(.no_after):after {
  content: "\2212";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.accordion__header:not(.accordion__header--disabled):not(.accordion__header--active):not(.no_after):after {
  content: '\002B';
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.accordion__header--active, .accordion__header:hover, .accordion__header--disabled:hover {
  background-color: #ccc;
  border: 1px solid #ccc;
}

.accordion__body--expand {
  height: auto;
  padding: 1rem;
  overflow: auto;
  transition: height 0.2s ease-out;
}

.modal_description {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.modal_description p {
  margin: 0;
}

.modal_description h3 {
  margin-top: 0;
}

.periods-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Cambia de "center" a "flex-start" */
  height: 100vh;

}

.periods-info {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.periods-table {
  margin-top: 5rem;
  margin-bottom: 5rem;

}

.volver-button {
  margin-right: auto;
  margin-left: 0px;
  display: flexbox;
}

.btn-students {
  margin-top: 0rem;
  margin-left: 200px;
}


.btn-addse:hover {
  background-color: #ccc;
}

.select-semester.year {
  font-weight: bold;
  margin-bottom: 8px;
}

.select-semester {
  width: 96%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.select-year {
  width: 50%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-semester{
  width: 100%;
  padding: 14px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 20px;
}

.btn-save-semester {
  width: 100%;
  padding: 0px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 30px;
}

.instructive-semester {
  width: 100%;
  padding: 0px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 30px;
}

.return-semester {
  width: 100%;
  padding: 0px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 100px;
}

.semester-cre {
  margin-left: 140px;
}

