.grading-matrix-radio-container > div > label {
    margin: 0;
    font-weight: 400;
    cursor: pointer;
}
.grading-matrix-comment-container > {
    margin: 0;
    font-weight: 400;
    text-align: justify;
}

input[type=radio] {
    cursor: pointer;
}

.center-div {
    text-align: center;
}


/* Standard Tables */


 
.grading-matrix-tbl table tr th {
  vertical-align: bottom;
}

.grading-matrix-tbl table tr:nth-child(even) th[scope=row] {
  background-color: #f4f1f1;
}

.grading-matrix-tbl table tr:nth-child(odd) th[scope=row] {
  background-color: #f9f9f9;
}

/* Fixed Headers */


.grading-matrix-tbl table tr th {
    vertical-align: bottom;
    background-color: #333;
    color: WHITE;
  }
.grading-matrix-tbl table tr th[scope=row] {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 1;
}

.grading-matrix-tbl table tr th[scope=row] {
  vertical-align: top;
  color: inherit;
  background-color: inherit;
  background: linear-gradient(90deg, transparent 0%, transparent calc(100% - .05em), #d6d6d6 calc(100% - .05em), #d6d6d6 100%);
}
.grading-matrix-tbl table thead tr th {
    position: -webkit-sticky;
    position: sticky !important;
    top: 0;
  }

/* Strictly for making the scrolling happen. */

.grading-matrix-tbl table tr th[scope=row]  {
  min-width: 15em;
}

.scrolling-list-m{
    overflow-y: auto;
    height: 600px;
}
.grading-matrix-tbl table tr td{
    min-width: 20em; 
}

.grading-matrix-tbl .small-td{
    min-width: 5em;  
}