#quill-container {
    max-height: 600px;
    margin-bottom: 60px;
    min-height: 100%;
    background-color: #ffffff;
}
#quill-container-tests {
    margin-bottom: 15px;
    min-height: 350px;
    background-color: #ffffff;
}

.editor, .editor > .ql-container > .ql-editor{
    min-height: 350px;
    max-height: 550px;
}

.editor > .ql-container {
    min-height: 350px;
    background-color: white;
}

.multimedia-recorder {
    height: 600px;
}

.input-range__label-container {
    color: black;
    font-size: 10px;
    font-family: 'Lato', sans-serif;
}
.quillFlex {
    display: flex;
    height: 127px;
    flex-flow: column nowrap;
}
.quillFlex div:nth-child(2) {
    background-color: white;
    flex-grow: 1;
}
.videoInstructionsText > .ql-container {
    background-color: white;
}

.scrollable-list {
    max-height: 200px; 
    overflow-y: auto;
  }

/* Estilos generales para centrar y ajustar el espacio entre elementos */
.add-student-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }
  
  .add-student-container input[type="email"] {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .add-student-container button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-student-container button:hover {
    background-color: #45a049;
  }
  
  .add-student-container button:active {
    background-color: #3e8e41;
  }
  